.simplebar-track {
  pointer-events: all !important;
  cursor: pointer;
  .simplebar-scrollbar {
    &:before {
      background-color: $color_1;
    }
    &:hover {
      &:before {
        opacity: 0.8;
      }
    }
  }
}