@import
	'assets/scss/base/variables',
    'assets/scss/base/mixins';

.block-portfolio-item {
    background-color: rgba($color_4, 0.8);
    border-radius: 10px;
    margin-bottom: 30px;
    @include boxy-shadow($color_2 + ' 0px 0px 8px');
    overflow: hidden;
    .wrapperImage {
        height: 280px;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        &.under-construction {
            margin-left: 20px;
            margin-right: 20px;
            background-position: center !important;
            background-size: contain;
        }
    }
    .wrapper-info {
        padding: 20px;
        min-height: 86px;
        .title {
            font-size: 20px;
            text-transform: uppercase;
        }
        .url {
            font-size: 16px;
            margin-top: 10px;
        }
    }
}