@import
	'assets/scss/base/variables',
    'assets/scss/base/mixins';

.block-dark-wrapper {
    background-color: rgba($color_4, 0.8);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    @include boxy-shadow($color_2 + ' 0px 0px 8px');
    &[account-for-scrolling='true'] {
        padding: 0px;
        margin-bottom: 0px;
        .simplebar-content {
            padding: 30px !important;
        }
    }
}