/* Colors -----------------------------------------------------------------*/

$color_1: #FFFFFF; // White
$color_2: #000000; // Black
$color_3: #FF6600; // Orange
$color_4: #1D1B1B; // Dark

/* Fonts ------------------------------------------------------------------*/

$default_font_stack: 'Roboto Condensed', sans-serif;
$font_awesome_5_pro: 'Font Awesome 5 Pro';
$default_font_size: 16px;
$default_font_weight: 400;
$default_line_height: 1;
$default_font_color: $color_1;

/* Other ------------------------------------------------------------------*/

$regular_content_width: 1180px + 30px; // content + outside gutter (left and right together)
$image_directory: '../../images/';
$default_transition_time: 0.3s;
