@import
	'assets/scss/base/variables',
	'assets/scss/base/mixins';

@import
    'assets/scss/vendors/bootstrap/bootstrap-variables-override';

body {
    @include bgimgin('background.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.layout-page-portfolio {
    .wrapper-simplebar {
        overflow-x: hidden;
        max-height: calc(100vh - 200px);
    }
    &.android,
    &.ios {
        .block-portfolio-item {
            .wrapperImage {
                background-position: center top;
            }
        }
    }
    .block-submenu {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        nav {
            display: inline-block;
            border-top: 1px solid $color_4;
            border-bottom: 1px solid $color_4;
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    flex: 0 0 auto;
                    align-self: center;
                    &:not(.logo) {
                        a {
                            display: block;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            padding-left: 20px;
                            padding-right: 20px;
                            font-size: 18px;
                            color: $color_4;
                            &.active {
                                font-weight: 700;
                                text-decoration: underline;
                            }
                        }
                    }
                    &.logo {
                        a {
                            display: block;
                            img {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: map-get($grid-breakpoints, 'md') - 1px) {
    body {
        background-position: 38% 50%;
    }
}

@media (max-width: 456px) {
    .layout-page-portfolio {
        .wrapper-simplebar {
            max-height: calc(100vh - 260px);
        }
    }
}