/*  Ways to include fonts:

	URL references to outside server:
		+ for an example, if it's a google font, there is a high chance their servers have less latency and better caching mechanism
		+ easier font management, just 1 line in header

	URL references to local server:
		- complicated font management
		- if you have to generate webfont yourself, then fonts generated by webfont generators (like fontsquirrel) need to be manually adjusted in their settings, because they tend to come out distorted

	Base64:
		- complicated font management
		+ bigger latency, less bandwidth

 */

// Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

 // Font Awesome 5 Pro
@import 'assets/fonts/fontawesome-pro-5.12.0-web/css/all.min.css';