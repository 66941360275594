/* Safari 10.1+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

	.inputblock {
		input, textarea, select {
			font-size: 16px !important; /* Prevents native safari zoom */
		}
	}

}}
