@import
    'assets/scss/base/variables',
	'assets/scss/vendors/bootstrap/bootstrap-variables-override';

.layout-header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    nav {
        display: inline-block;
        ul {
            display: flex;
            li {
                flex: 0 0 auto;
                align-self: center;
                &:not(.logo) {
                    a {
                        display: block;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-size: 24px;
                        color: $color_4;
                        width: 126px;
                        white-space: nowrap;
                        &.active {
                            font-weight: 700;
                            text-decoration: underline;
                        }
                    }
                }
                &.logo {
                    a {
                        display: block;
                        img {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: map-get($grid-breakpoints, 'sm') - 1px) {
    .layout-header {
        nav {
            ul {
                margin-left: -15px;
                margin-right: -15px;
                li {
                    &:not(.logo) {
                        a {
                            font-size: 19px;
                            width: auto;
                        }
                    }
                    &.logo {
                        a {
                            img {
                                max-width: 94px;
                            }
                        }
                    }
                }
            }
        }
    }
}