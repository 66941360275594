/* Media queries:
	all - both screen and print (default)
	screen - desktop and mobile devices with screen
	print - only print devices
*/

/* Resets / Forcing default behaviour */
input, button {
	padding: 0px;
	border: 0px;
	&[type='submit'] {
		cursor: pointer;
	}
}
a, button {
	outline: none !important;
}
@include placeholder {
	color: $color_4;
	font-style: italic;
}
img {
	max-width: 100%;
}
ol, ul {
	list-style: none;
	margin: 0px;
	padding: 0px;
}
iframe {
	display: block;
}
b, strong {
	font-weight: 700;
}
input, textarea, select, button {
	-webkit-appearance: none !important;
}
select, button {
	cursor: pointer;
}
hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border-color: rgba($color_4, 0.2);
}
/* Hides number input arrows: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type='number'] {
	-moz-appearance:textfield;
}
/* Generic helpers */
.overflow-hidden {
	overflow: hidden;
}
.responsive-table-wrapper {
	overflow: auto;
}
.row {
	&.direct-childs-collapsible {
		& > div {
			flex: 0 1 auto;
			width: auto;
		}
	}
}
a {
	color: $color_3;
	text-decoration: none;
	transition: all $default_transition_time;
	&:hover,
	&:focus {
		color: $color_3;
		text-decoration: underline;
	}
	&:active {
		text-decoration: underline;
	}
}
.transform-none {
	transform: none !important;
}