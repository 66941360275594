@import
	'assets/scss/base/variables',
	'assets/scss/base/mixins';

@import
    'assets/scss/vendors/bootstrap/bootstrap-variables-override';

body {
    @include bgimgin('background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.layout-page-about-me {
    .wrapper-simplebar {
        overflow-x: hidden;
        max-height: calc(100vh - 160px);
    }
}

@media (max-width: map-get($grid-breakpoints, 'md') - 1px) {
    body {
        background-position: 38% 50%;
    }
}