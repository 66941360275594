// h1,
// .styled-as-h1 {
// 	font-family: $roboto_font_stack;
// 	font-weight: 700;
// 	font-size: 150px;
// 	line-height: 120px;
// 	text-transform: uppercase;
// 	margin-bottom: 28px;
// 	color: $color_15;
// 	span {
// 		display: block;
// 		color: $color_4;
// 	}
// }
h2,
.styled-as-h2 {
	font-weight: 700;
	font-size: 24px;
	line-height: 1;
	margin-top: 0px;
	margin-bottom: 20px;
}
// h3,
// .styled-as-h3 {
// 	font-family: $roboto_font_stack;
// 	font-weight: 700;
// 	font-size: 40px;
// 	line-height: 1.15;
// 	text-transform: uppercase;
// 	margin-bottom: 28px;
// 	color: $color_15;
// }
// h4,
// .styled-as-h4 {
// 	font-family: $default_font_stack;
// 	font-weight: 700;
// 	font-size: 32px;
// 	line-height: 35px;
// 	margin-bottom: 28px;
// 	color: $color_15;
// }
// h5,
// .styled-as-h5 {
// 	font-family: $default_font_stack;
// 	font-weight: 700;
// 	font-size: 24px;
// 	line-height: 35px;
// 	margin-bottom: 28px;
// 	color: $color_15;
// }
p {
	margin-bottom: 34px;
}

.typography-style {
	font-family: $default_font_stack;
	font-size: $default_font_size;
	line-height: 1.15;
	font-weight: $default_font_weight;
	color: $color_1;
	p {
		&:last-child {
			margin-bottom: 0px;
		}
	}
	ul {
		list-style: none;
		margin-left: 0px;
		li {
			position: relative;
			padding-left: 20px;
			&:before {
				content: '\f105';
				font-family: $font_awesome_5_pro;
				font-weight: 300;
				font-size: 16px;
				line-height: 1;
				position: absolute;
				top: 1px;
				left: 0px;
				color: $color_1;
			}
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

// @media (min-width: map-get($grid-breakpoints, 'lg')) and (max-width: $large_laptop_width - 1px) {
// 	.typography-style {
// 		font-size: 16px;
// 		line-height: 24px;
// 		ul {
// 			li {
// 				padding-left: 30px;
// 				&:before {
// 					top: 4px;	
// 				}
// 			}
// 		}
// 	}
// 	h1,
// 	.styled-as-h1 {
// 		font-size: 100px;
// 		line-height: 80px;
// 	}
// 	h2,
// 	.styled-as-h2 {
// 		font-size: 46px;
// 		line-height: 40px;
// 	}
// 	h3,
// 	.styled-as-h3 {
// 		font-size: 26px;
// 		line-height: 1.15;
// 	}
// 	h4,
// 	.styled-as-h4 {
// 		font-size: 16px;
// 		line-height: 20px;
// 	}
// 	h5,
// 	.styled-as-h5 {
// 		font-size: 16px;
// 		line-height: 20px;
// 	}
// }

// @media (max-width: map-get($grid-breakpoints, 'lg') - 1) {
// 	.typography-style {
// 		font-size: 16px;
// 		line-height: 24px;
// 		ul {
// 			li {
// 				padding-left: 30px;
// 				margin-bottom: 20px;
// 				&:before {
// 					top: 4px;	
// 				}
// 			}
// 		}
// 		blockquote {
// 			padding-left: 0px;
// 			&:before {
// 				display: none;
// 			}
// 		}
// 	}
// 	h1,
// 	.styled-as-h1 {
// 		font-size: 50px;
// 		line-height: 40px;
// 	}
// 	h2,
// 	.styled-as-h2 {
// 		font-size: 30px;
// 		line-height: 28px;
// 	}
// 	h3,
// 	.styled-as-h3 {
// 		font-size: 24px;
// 	}
// 	h4,
// 	.styled-as-h4 {
// 		font-size: 24px;
// 	}
// 	h5,
// 	.styled-as-h5 {
// 		font-size: 18px;
// 		line-height: 24px;
// 	}
// }